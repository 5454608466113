import React from "react";
import styles from "./Technologies.module.css";
import html from "../../images/technologies/html.svg";
import wordpress from "../../images/technologies/wordpress.svg";
import css from "../../images/technologies/css.svg";
import php from "../../images/technologies/php.svg";
import javascript from "../../images/technologies/javascript.svg";
import nodejs from "../../images/technologies/nodejs.svg";
import python from "../../images/technologies/python.svg";
import react from "../../images/technologies/react.svg";
import reactNative from "../../images/technologies/react-native.svg";
import shopify from "../../images/technologies/shopify.svg";

const Technologies = () => {
  return (
    <div>
      <h1 className={`main-heading`}>Technologies we work with</h1>
      <p className={`sub-heading`}>
        These are the development technologies we use on a daily basis to
        fulfill our clients needs.
      </p>
      <div className={styles.tech_container}>
        <div className={styles.tech}>
          <img src={html} alt="html" className={styles.image} />
          <p className={styles.text}>HTML</p>
        </div>
        <div className={styles.tech}>
          <img src={css} alt="css" className={styles.image} />
          <p className={styles.text}>CSS</p>
        </div>
        <div className={styles.tech}>
          <img src={javascript} alt="javascript" className={styles.image} />
          <p className={styles.text}>JavaScript</p>
        </div>
        <div className={styles.tech}>
          <img src={php} alt="php" className={styles.image} />
          <p className={styles.text}>PHP</p>
        </div>
        <div className={styles.tech}>
          <img src={wordpress} alt="wordpress" className={styles.image} />
          <p className={styles.text}>WordPress</p>
        </div>
        <div className={styles.tech}>
          <img src={nodejs} alt="nodejs" className={styles.image} />
          <p className={styles.text}>Node.js</p>
        </div>
        <div className={styles.tech}>
          <img src={python} alt="python" className={styles.image} />
          <p className={styles.text}>Python</p>
        </div>
        <div className={styles.tech}>
          <img src={react} alt="react" className={styles.image} />
          <p className={styles.text}>React</p>
        </div>
        <div className={styles.tech}>
          <img src={reactNative} alt="react-native" className={styles.image} />
          <p className={styles.text}>React Native</p>
        </div>
        <div className={styles.tech}>
          <img src={shopify} alt="shopify" className={styles.image} />
          <p className={styles.text}>Shopify</p>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
