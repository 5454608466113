import React from "react";
import Card from "../Card";
import styles from "./HowWeWork.module.css";

const HowWeWork = () => {
  return (
    <>
      <h1 className="main-heading">How we work</h1>
      <p className="sub-heading">
        Our step by step process on how we work with your project.
      </p>
      <div className={styles.container}>
        <Card
          heading="1. Planning"
          text="First, we start your project by planning what needs to be done and what are the best technologies for the job."
        />
        <Card
          heading="2. Design"
          text="Then we proceed to making a good design for your product or service. That identifies with your branding."
        />
        <Card
          heading="3. Development"
          text="Then we proceed to the development phase where we use agile methods to work on your desired product or service."
        />
        <Card
          heading="4. Delivery"
          text="After thoroughly testing your product, we will deliver to you the final product and make adjustments if required."
        />
      </div>
    </>
  );
};

export default HowWeWork;
