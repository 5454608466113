import React from "react";
import formStyles from "../Form.module.css";
import styles from "./ReqForm.module.css";
import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";

const WebForm = () => {
  return (
    <div className={styles.form_wrapper}>
      <Link to="/">
        <img src={logo} alt="fisbyte-logo" className={styles.logo} />
      </Link>
      <div className={styles.form_container}>
        <form className={formStyles.form} name="web-form" method="POST">
          <input type="hidden" name="form-name" value="web-form" />
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Your full name"
            className={formStyles.form_input}
          />
          <label>E-mail</label>
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            className={formStyles.form_input}
          />
          <label htmlFor="question-1">
            Describe your business in a few sentences.
          </label>
          <input
            type="text"
            id="question-1"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-2">
            What is your business phone number?
          </label>
          <input
            type="number"
            id="question-2"
            name="number"
            placeholder="Phone number"
            className={formStyles.form_input}
          />
          <label htmlFor="question-3">
            What specific features do you want on the site?
          </label>
          <input
            type="text"
            id="question-3"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-4">What makes your services unique?</label>
          <input
            type="text"
            id="question-4"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-5">
            Do you have any existing style guides or specific color preferences?
          </label>
          <input
            type="text"
            id="question-5"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-6">
            Can you list us your services and prices?
          </label>
          <input
            type="text"
            id="question-6"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-7">
            What goals do you want your website to achieve?
          </label>
          <input
            type="text"
            id="question-7"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-8">
            What image, look or feel do you want your website brand to reflect?
          </label>
          <input
            type="text"
            id="question-8"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-9">
            Tell us your company name and slogan if you have one.
          </label>
          <input
            type="text"
            id="question-9"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-10">
            Tell us which pages are essential for your website.
          </label>
          <input
            type="text"
            id="question-10"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-11">
            What kind of actions do you want your visitors to take on your
            website?
          </label>
          <input
            type="text"
            id="question-11"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-12">
            What specific functionality will you need?
          </label>
          <input
            type="text"
            id="question-12"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <p>Will you send us all the images, products and files?</p>
          <div>
            <input
              type="radio"
              id="yes"
              name="radio-question"
              value="yes"
              className={formStyles.radio_input}
            />
            <label for="yes">Yes</label>
          </div>
          <div>
            <input
              type="radio"
              id="no"
              name="radio-question"
              value="no"
              className={formStyles.radio_input}
            />
            <label for="no">No</label>
          </div>
          <label htmlFor="question-13">
            What are the most frequent questions from your clients?
          </label>
          <input
            type="text"
            id="question-13"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <label htmlFor="question-14">
            What are the working days and hours of your business?
          </label>
          <input
            type="text"
            id="question-14"
            name="question"
            placeholder="Short answer text"
            className={formStyles.form_input}
          />
          <input
            type="submit"
            value="Send Request"
            className={formStyles.form_button}
          />
        </form>
      </div>
    </div>
  );
};

export default WebForm;
