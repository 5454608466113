import React, { useState } from "react";
import styles from "./Hero2.module.css";
import Button from "./Button";
import { FaLongArrowAltRight } from "react-icons/fa";

const Hero2 = (props) => {
  const [showButton] = useState(props.showButton);
  const [flexWrap] = useState(props.wrap);
  const [flexDirection] = useState(props.flexDirection);

  return (
    <div>
      <div
        className={styles.hero2_container}
        style={{ flexWrap: { flexWrap }, flexDirection: `${flexDirection}` }}
      >
        <img
          src={props.img}
          alt="vision"
          className={styles.hero2_image}
          loading="lazy"
        />
        <div className={styles.hero2_content}>
          <h1 className={styles.hero2_header}>{props.header}</h1>
          <p className={styles.hero2_p}>{props.text}</p>
          {showButton === true && (
            <Button className={styles.hero2_button} direction={props.link}>
              {props.btnText}{" "}
              <FaLongArrowAltRight
                style={{
                  verticalAlign: "top",
                  fontSize: "14px",
                  display: "inline-block",
                }}
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero2;
