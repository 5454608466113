import React from "react";
import LoginComponent from "../components/LoginComponent";

const Login = () => {
  return (
    <>
      <LoginComponent />
    </>
  );
};

export default Login;
