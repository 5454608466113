import React from "react";
import formStyles from "./Form.module.css";
import styles from "./LoginComponent.module.css";
import logo from "../images/logo.svg";

const LoginComponent = () => {
  return (
    <div className={styles.contact_form}>
      <img src={logo} alt="fisbyte-logo" className={styles.logo} />
      <div className={styles.form_container}>
        <form className={formStyles.form} id="login-form">
          <label htmlFor="">E-mail</label>
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            className={formStyles.form_input}
          />
          <label htmlFor="">Password</label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className={formStyles.form_input}
          />
          <input type="button" value="Log In" className={styles.form_button} />
          <div className={styles.horizontal_container}>
            <hr className={styles.horizontal_line} />
            <p className={styles.horizontal_text}>or</p>
            <hr className={styles.horizontal_line} />
          </div>
          <input
            type="button"
            value="Request Platform"
            className={styles.form_button_dark}
          />
          <p style={{ marginTop: "15px", textAlign: "center" }}>
            Fisbyte's app platform is still in development and not available.
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginComponent;
