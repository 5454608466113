import React from "react";
import {
  FaUsers,
  FaHandshake,
  FaBalanceScale,
  FaChartLine,
} from "react-icons/fa";
import styles from "./Values.module.css";

const Values = () => {
  return (
    <div>
      <h1 className="main-heading">Our Values</h1>
      <p className="sub-heading">
        We hold on to this values to create an amazing working environment.
      </p>
      <div className={styles.values}>
        <div className={styles.value_container}>
          <div className={styles.icon_container}>
            <FaUsers className={styles.icon} />
          </div>
          <h2 className={styles.value_heading}>Teamwork</h2>
          <p className={styles.value_text}>
            Working as a team is incredibly important. No matter how hard or
            easy the project we are given we always focus on working as a team.
          </p>
        </div>
        <div className={styles.value_container}>
          <div className={styles.icon_container}>
            <FaHandshake className={styles.icon} />
          </div>
          <h2 className={styles.value_heading}>Professionalism</h2>
          <p className={styles.value_text}>
            Professionalism is a quality that all great teams need to have.
            That's why we have a big emphasis on customer support.
          </p>
        </div>
        <div className={styles.value_container}>
          <div className={styles.icon_container}>
            <FaBalanceScale className={styles.icon} />
          </div>
          <h2 className={styles.value_heading}>Ethics</h2>
          <p className={styles.value_text}>
            We believe having great ethics is an amazing value that isn't
            practiced a lot. At Fisbyte we try to make it one of our main
            priorities.
          </p>
        </div>
        <div className={styles.value_container}>
          <div className={styles.icon_container}>
            <FaChartLine className={styles.icon} />
          </div>
          <h2 className={styles.value_heading}>Quality</h2>
          <p className={styles.value_text}>
            I think we can all agree on the importance of having a product that
            has great quality in it. Well we make quality our #1 priority and
            value.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Values;
