import React from "react";
import styles from "./Faq.module.css";
import { Accordion } from "react-accessible-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./react-tabs.css";
import FaqItem from "./FaqItem";

const Faq = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Web</Tab>
          <Tab>Applications</Tab>
          <Tab>Design</Tab>
          <Tab>Marketing</Tab>
        </TabList>

        <TabPanel>
          <div className={styles.faq_container}>
            <Accordion className={styles.accordion}>
              <FaqItem
                question="How long does it take to build a website or web application?"
                answer="Creating a website or web application may take anything from
                    2 weeks to 1 year depending on your needs. Most of the small
                    to average projects take anything from 2 weeks to a month to
                    complete."
              />
              <FaqItem
                question="Can you guys take care of the domain registration and
                    hosting for us?"
                answer="For most of our clients, we are the ones responsible for managing their servers as well as their domains. We take care of all your hosting needs by working with top tier providers. However, we require you to pay a monthly fee that goes along with your domain and hosting selection. "
              />
              <FaqItem
                question="What types of online payment do you accept?"
                answer="We accept online payments of all sorts. You can pay us with
                    PayPal, credit or debit card, payoneer and bank transfer. In
                    the future we look forward to enabling clients to pay us
                    through our application platform."
              />
              <FaqItem
                question="Do I have to be in your country to work with you?"
                answer="No. We work with clients all over the world. Our whole team works remotely. Most of our clients are from Serbia, the Dominican Republic and the USA.  "
              />
              <FaqItem
                question="When do I have to pay?"
                answer="We expect a 30% advanced payment before we start working on
                your project or idea. Then we get paid the remaining 70%
                after project completion."
              />
              <FaqItem
                question="Who is the one that writes the content for the website or
                branding?"
                answer="In most cases, you or the client is the one that sends us all the information needed, as well as the images that are required to be displayed to showcase your company or brand."
              />
              <FaqItem
                question="How big are the companies you currently work with?"
                answer="We have worked from start-ups all the way to very big
                companies in a wide number of fields. You can count on us
                that we will know what is the best solution for your
                organization."
              />
              <FaqItem
                question="Do you work internationally?"
                answer="Despite of our focus mainly being the USA and the Dominican
                Republic, we do offer our services to anyone that requires
                them."
              />
            </Accordion>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={styles.faq_container}>
            <Accordion className={styles.accordion}>
              <FaqItem
                question="Can I purchase my own domain?"
                answer="Of course, you are able to purchase your own domain and choose your web host. We will take control of things from that point."
              />
              <FaqItem
                question="Can you convert my existing website into WordPress?"
                answer="Yes, we can also help you manage it as well as add new
                content to it."
              />
              <FaqItem
                question="What is web hosting?"
                answer="Web hosting is required to get your website or web
                application online. It's the space you rent on the internet
                that belongs to you."
              />
            </Accordion>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={styles.faq_container}>
            <Accordion className={styles.accordion}>
              <FaqItem
                question="What kind of mobile applications do you create?"
                answer="We create mobile applications for all platforms that can help our clients have an edge on what they do."
              />
              <FaqItem
                question="Should I create a dedicated website for my app?"
                answer="Not necessarily but it does have its advantages. For one, it gives users the ready impression that you are a professional developer. This is crucial for establishing great first impressions and the acquisition of users over time."
              />
              <FaqItem
                question="Who should I be targeting?"
                answer="Your current or future customers, employees and partners that will be able to work or operate with your application."
              />
              <FaqItem
                question="How much time would it take for the app to develop?"
                answer="Developing an app can take anywhere from 2 months to an entire year or more. It really depends on your needs and what kind of application are you exactly creating."
              />
            </Accordion>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={styles.faq_container}>
            <Accordion className={styles.accordion}>
              <FaqItem
                question="Do you offer full company branding packages?"
                answer="Yes, we offer full company branding packages, in fact we also help new brands have a strong digital presence."
              />
              <FaqItem
                question="Where is graphic design used?"
                answer="You will find graphic design in company logos, printed materials like brochures, posters, signs, greeting cards, postcards, business cards, billboards and ads."
              />
              <FaqItem
                question="Do I get all the artwork files?"
                answer="Yes, you get all the vector as well as raster graphics files."
              />
            </Accordion>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={styles.faq_container}>
            <Accordion className={styles.accordion}>
              <FaqItem
                question="What is digital marketing?"
                answer="Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers."
              />
              <FaqItem
                question="Would my business benefit from digital marketing?"
                answer="Definitely. Though companies in many business categories continue to approach digital marketing with skepticism, avoiding digital marketing denies your business access to the media. The majority of consumers turn to them first and at all hours of the day."
              />
              <FaqItem
                question="What is SEO?"
                answer="Search engine optimization (SEO) is the process of using onsite and offsite tactics to help get content presented by a search engine."
              />
            </Accordion>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default Faq;
