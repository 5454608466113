import React from "react";

const Banner = (props) => {
  return (
    <div className={props.style}>
      <p className="upper-banner-heading">{props.description}</p>
      <h1 className="banner-heading">{props.heading}</h1>
    </div>
  );
};

export default Banner;
