import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaInstagram,
  FaFacebook,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className={styles.footer_container}>
        <div className={styles.links}>
          <h1 className={styles.header}>Company</h1>
          <ul className={styles.link_list}>
            <li>
              <Link to="/" className={styles.link}>
                Home
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/clients" className={styles.link}>
                Clients
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/about" className={styles.link}>
                About
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/contact" className={styles.link}>
                Contact
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/faq" className={styles.link}>
                FAQs
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.links}>
          <h1 className={styles.header}>Services</h1>
          <ul className={styles.link_list}>
            <li>
              <Link to="/web-development" className={styles.link}>
                Web Development
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/graphic-design" className={styles.link}>
                Graphic Design
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/marketing" className={styles.link}>
                Marketing
              </Link>
            </li>
            <li className={styles.list_item}>
              <Link to="/app-development" className={styles.link}>
                App Development
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.contact}>
          <h1 className={styles.header}>Contact</h1>
          <p className={styles.paragraph}>
            <FaMapMarkerAlt
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#0670ef",
                marginRight: "10px",
              }}
            />
            Belgrade, Vitezova Karadjordjeve Zvezde
          </p>
          <p className={styles.paragraph}>
            <FaEnvelope
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#0670ef",
                marginRight: "10px",
              }}
            />
            support@fisbyte.com
          </p>
        </div>
        <div className={styles.follow}>
          <h1 className={styles.header}>Follow us</h1>
          <a href="https://www.instagram.com/fisbyte/">
            <div className={styles.icon_container}>
              <FaInstagram className={styles.social_icon} />
            </div>
          </a>
          <a href="https://www.facebook.com/fisbyte/">
            <div
              className={styles.icon_container}
              style={{ marginLeft: "10px" }}
            >
              <FaFacebook className={styles.social_icon} />
            </div>
          </a>
          <a href="/">
            <div
              className={styles.icon_container}
              style={{ marginLeft: "10px" }}
            >
              <FaLinkedinIn className={styles.social_icon} />
            </div>
          </a>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>© Fisbyte 2022 - All rights reserved</p>
        <Link to="/privacy-policy" className={styles.policy_link}>
          Privacy Policy
        </Link>
        |
        <Link to="/terms-of-use" className={styles.policy_link}>
          Terms of Use
        </Link>
        |
        <Link to="/cookies-policy" className={styles.policy_link}>
          Cookies Policy
        </Link>
      </div>
    </>
  );
};

export default Footer;
