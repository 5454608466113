import React, { useState } from "react";
import Button from "./Button";
import styles from "./Card.module.css";

const Card = ({ cardButton, heading, text }) => {
  const [showButton] = useState(cardButton);

  return (
    <div className={styles.card_container}>
      <div className={styles.card}>
        <h2 className={styles.heading}>{heading}</h2>
        <p className={styles.card_text}>{text}</p>
        {showButton === true && <Button></Button>}
      </div>
    </div>
  );
};

export default Card;
