import React from "react";
import styles from "./Advantages.module.css";

const Advantages = ({
  mainHeading,
  subHeading,
  heading1,
  heading2,
  heading3,
  heading4,
  text1,
  text2,
  text3,
  text4,
}) => {
  return (
    <>
      <h1 className="main-heading">{mainHeading}</h1>
      <p className="sub-heading">{subHeading}</p>
      <div className={styles.container}>
        <h2 className={styles.heading}>{heading1}</h2>
        <p className={styles.text}>{text1}</p>
        <h2 className={styles.heading}>{heading2}</h2>
        <p className={styles.text}>{text2}</p>
        <h2 className={styles.heading}>{heading3}</h2>
        <p className={styles.text}>{text3}</p>
        <h2 className={styles.heading}>{heading4}</h2>
        <p className={styles.text}>{text4}</p>
      </div>
    </>
  );
};

export default Advantages;
