import React from "react";
import Footer from "../components/Footer";
import Hero3 from "../components/Hero3";
import Navbar from "../components/Navbar";
import Advantages from "../components/Services/Advantages";
import ServicesHero from "../components/Services/ServicesHero";
import designImg from "../images/service-2.svg";

const GraphicDesign = () => {
  return (
    <>
      <Navbar />
      <ServicesHero
        img={designImg}
        heading="Graphic Design"
        text="Design is an essential part of every product or service. Without it you can't really create anything. We focus on creating designs that will not only satisfy your needs but will also impress your customers."
      />
      <Advantages
        mainHeading="Key design elements"
        subHeading="Key elements that help have a great design."
        heading1="Simplicity"
        heading2="Recognizability"
        heading3="Uniqueness"
        heading4="Authenticity"
        text1="In Fisbyte we believe that simplicity is the key to great design. Nothing captures the eyes of people more like something that's easy to remember."
        text2="A great design will be easy to recognize no matter where or how you see it."
        text3="If it's not unique in it's own way, it will not have such a big impact. Uniqueness in the design is a very important thing when it comes to attracting attention."
        text4="It can be hard to create a design that's authentic, but if it actually is authentic, it will have a one of a kind value."
      />
      <Hero3 />
      <Footer />
    </>
  );
};

export default GraphicDesign;
