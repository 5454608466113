import React from "react";
import styles from "./Companies.module.css";
import namecheap from "../../images/hosting/namecheap.svg";
import cloudways from "../../images/hosting/cloudways.svg";
import cloudflare from "../../images/hosting/cloudflare.svg";
import digitalocean from "../../images/hosting/digitalocean.svg";
import netlify from "../../images/hosting/netlify.svg";

const Companies = () => {
  return (
    <div>
      <h1 className={`main-heading`}>World class web hosting providers</h1>
      <p className={`sub-heading`}>
        These are the companies we work with to offer high quality domains as
        well as fast hosting speeds depending on your needs.
      </p>
      <div className={styles.companies_container}>
        <div className={styles.company}>
          <img src={namecheap} alt="namecheap" className={styles.image} />
        </div>
        <div className={styles.company}>
          <img src={cloudflare} alt="cloudflare" className={styles.image} />
        </div>
        <div className={styles.company}>
          <img src={cloudways} alt="cloudways" className={styles.image} />
        </div>
        <div className={styles.company}>
          <img src={digitalocean} alt="digitalocean" className={styles.image} />
        </div>
        <div className={styles.company}>
          <img src={netlify} alt="netlify" className={styles.image} />
        </div>
      </div>
    </div>
  );
};

export default Companies;
