import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styles from "../components/Banner.module.css";
import Form from "../components/Form";
import Hero3 from "../components/Hero3";

const Contact = () => {
  return (
    <>
      <Navbar />
      <Banner
        heading="Contact Us"
        style={styles.container}
        description="Ask us anything you want to know"
      />
      <Form />
      <Hero3 />
      <Footer />
    </>
  );
};

export default Contact;
