import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./fonts/fonts.css";
import "./App.css";
import Home from "./routes/Home";
import FAQ from "./routes/FAQ";
import About from "./routes/About";
import Clients from "./routes/Clients";
import Contact from "./routes/Contact";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./routes/Login";
import WebRequest from "./routes/WebRequest";
import AppRequest from "./routes/AppRequest";
import Request from "./routes/Request";
import WebDevelopment from "./routes/WebDevelopment";
import GraphicDesign from "./routes/GraphicDesign";
import Marketing from "./routes/Marketing";
import AppDevelopment from "./routes/AppDevelopment";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfUse from "./routes/TermsOfUse";
import CookiesPolicy from "./routes/CookiesPolicy";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/request" element={<Request />} />
          <Route path="/log-in" element={<Login />} />
          <Route path="/web-request" element={<WebRequest />} />
          <Route path="/app-request" element={<AppRequest />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/graphic-design" element={<GraphicDesign />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/app-development" element={<AppDevelopment />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
