import React from "react";
import styles from "./Numbers.module.css";

const Numbers = () => {
  return (
    <div className={styles.container}>
      <div className={styles.stat}>
        <h2 className={styles.header}>7+</h2>
        <p className={styles.paragraph}>Companies</p>
      </div>
      <div className={styles.stat}>
        <h2 className={styles.header}>61+</h2>
        <p className={styles.paragraph}>Projects</p>
      </div>
      <div className={styles.stat}>
        <h2 className={styles.header}>6</h2>
        <p className={styles.paragraph}>Months of Service</p>
      </div>
      <div className={styles.stat}>
        <h2 className={styles.header}>100%</h2>
        <p className={styles.paragraph}>Customer Satisfaction</p>
      </div>
    </div>
  );
};

export default Numbers;
