import React from "react";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import RequestComponent from "../components/RequestComponent";
import styles from "../components/Banner.module.css";

const Request = () => {
  return (
    <>
      <Navbar />
      <Banner
        heading="Select a Solution"
        description="Different types of solutions we offer"
        style={styles.container2}
      />
      <RequestComponent />
    </>
  );
};

export default Request;
