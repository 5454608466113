import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo.svg";
import { FaCaretDown } from "react-icons/fa";
import { IoEarthOutline, IoPersonOutline } from "react-icons/io5";
import usaFlag from "../images/flags/usa.svg";
import spainFlag from "../images/flags/spain.svg";
import styles from "./Navbar.module.css";
import Button from "./Button";

const Navbar = () => {
  const [toggleBar, setToggleBar] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);
  const [toggleLang, setToggleLang] = useState(false);
  const [toggleSubMenu, setSubMenu] = useState(false);

  const toggleBarHandler = () => {
    setToggleBar(!toggleBar);
    setToggleNav(!toggleNav);
  };

  const subMenuHandler = () => {
    setSubMenu(!toggleSubMenu);
  };

  const langHandler = () => {
    setToggleLang(!toggleLang);
  };

  let activeLink = {
    color: "#0670ef",
  };

  return (
    <div className={styles.nav_wrapper}>
      <nav className={styles.main_nav}>
        <div className={styles.nav_container}>
          <NavLink to="/">
            <img src={logo} alt="fisbyte-logo" className={styles.logo} />
          </NavLink>
          <ul
            className={
              !toggleNav
                ? styles.nav_list
                : `${styles.nav_list} ${styles.active_nav}`
            }
          >
            <li></li>
            <li>
              <NavLink
                to="/clients"
                className={styles.nav_link}
                style={({ isActive }) => (isActive ? activeLink : undefined)}
              >
                Clients
              </NavLink>
            </li>
            <li>
              <button
                className={`${styles.sub_menu_button}`}
                onClick={subMenuHandler}
              >
                Services <FaCaretDown className={styles.caret} />
                <div
                  className={`${styles.sub_menu} ${
                    toggleSubMenu ? styles.display_sub_menu : ""
                  }`}
                >
                  <Link to="/web-development" className={styles.sub_menu_link}>
                    Web Development
                    <p className={styles.sub_menu_p}>
                      Web development & web design
                    </p>
                  </Link>
                  <Link to="/graphic-design" className={styles.sub_menu_link}>
                    {" "}
                    Graphic Design
                    <p className={styles.sub_menu_p}>
                      Company or product branding
                    </p>
                  </Link>
                  <Link to="/marketing" className={styles.sub_menu_link}>
                    {" "}
                    Marketing
                    <p className={styles.sub_menu_p}>Social media management</p>
                  </Link>
                  <Link to="/app-development" className={styles.sub_menu_link}>
                    {" "}
                    App Development
                    <p className={styles.sub_menu_p}>Software solutions</p>
                  </Link>
                </div>
              </button>
            </li>
            <li>
              <NavLink
                to="/about"
                className={styles.nav_link}
                style={({ isActive }) => (isActive ? activeLink : undefined)}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={styles.nav_link}
                style={({ isActive }) => (isActive ? activeLink : undefined)}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                className={styles.nav_link}
                style={({ isActive }) => (isActive ? activeLink : undefined)}
              >
                FAQs
              </NavLink>
            </li>
            <li>
              <button
                className={`${styles.sub_menu_button} ${styles.hide_sub_menu}`}
                onClick={langHandler}
              >
                Languages <FaCaretDown className={styles.caret} />
                <div
                  className={`${styles.sub_menu} ${
                    toggleLang ? styles.display_langs : ""
                  }`}
                >
                  <a
                    href="https://www.fisbyte.com"
                    className={styles.sub_menu_link}
                  >
                    <img
                      src={usaFlag}
                      alt="english"
                      style={{
                        width: "22px",
                        verticalAlign: "middle",
                        margin: "15px",
                      }}
                    />
                    English
                  </a>
                  <a
                    href="https://es.fisbyte.com"
                    className={styles.sub_menu_link}
                  >
                    <img
                      src={spainFlag}
                      alt="español"
                      style={{
                        width: "22px",
                        verticalAlign: "middle",
                        margin: "15px",
                      }}
                    />
                    Español
                  </a>
                </div>
              </button>
            </li>
            <li>
              <Button
                className={`${styles.mobile_nav_button} ${styles.log_in_btn}`}
                direction="/log-in"
              >
                Log In
              </Button>
            </li>
            <li>
              <Button className={styles.mobile_nav_button} direction="/request">
                Send Request
              </Button>
            </li>
          </ul>
        </div>
        <div className={styles.button_container}>
          <button className={`${styles.sub_menu_button}`} onClick={langHandler}>
            <IoEarthOutline
              style={{
                fontSize: "22px",
                verticalAlign: "bottom",
              }}
            />
            <div
              className={`${styles.sub_menu} ${
                toggleSubMenu ? styles.display_sub_menu : ""
              }`}
            >
              <a
                href="https://www.fisbyte.com"
                className={styles.sub_menu_link}
              >
                <img
                  src={usaFlag}
                  alt="english"
                  style={{
                    width: "22px",
                    verticalAlign: "middle",
                    margin: "15px",
                  }}
                />
                English
              </a>
              <a href="https://es.fisbyte.com" className={styles.sub_menu_link}>
                <img
                  src={spainFlag}
                  alt="español"
                  style={{
                    width: "22px",
                    verticalAlign: "middle",
                    margin: "15px",
                  }}
                />
                Español
              </a>
            </div>
          </button>
          <NavLink to="/log-in" className={styles.end_button}>
            <IoPersonOutline
              style={{
                fontSize: "22px",
                verticalAlign: "bottom",
              }}
            />
          </NavLink>
          <Button className={styles.nav_button} direction="/request">
            Send Request
          </Button>
        </div>

        <div
          className={
            !toggleBar
              ? styles.burger_container
              : `${styles.burger_container} ${styles.change}`
          }
          id="burger-container"
          onClick={toggleBarHandler}
        >
          <div className={styles.bar1}></div>
          <div className={styles.bar2}></div>
          <div className={styles.bar3}></div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
