import React from "react";
import Footer from "../components/Footer";
import Hero3 from "../components/Hero3";
import Navbar from "../components/Navbar";
import Advantages from "../components/Services/Advantages";
import ServicesHero from "../components/Services/ServicesHero";
import web from "../images/service-1.svg";

const WebDevelopment = () => {
  return (
    <>
      <Navbar />
      <ServicesHero
        heading="Web Development"
        img={web}
        text="We help companies grow into better versions of themselves by offering them quality web development services. In this day and age, any company that wants to grow and become something more needs a web solution that can help it achieve its goals.  "
      />
      <Advantages
        mainHeading="How can the web help you?"
        subHeading="There are dozens of ways a web app or website can help you, here are
        some of the most important ones."
        heading1="Client retention"
        heading2="Higher credibility"
        heading3="Facilitated work"
        heading4="Marketing"
        text1="Companies with a website have a much higher client retention rate compared to the ones that don't."
        text2="A company with a website instantly has 30% more credibility than without it."
        text3="Any type of web solution can offer your clients an easier way to reach and talk to your company."
        text4="If you are looking to sell products or create a brand, is that even possible in this day and age without a web solution?  "
      />
      <Hero3 />
      <Footer />
    </>
  );
};

export default WebDevelopment;
