import React from "react";
import styles from "./ServicesHero.module.css";

const ServicesHero = (props) => {
  return (
    <div className={styles.hero_container}>
      <div className={styles.image_content}>
        <img src={props.img} alt="web" className={styles.image} />
      </div>
      <div className={styles.text_content}>
        <h1 className={styles.header}>{props.heading}</h1>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default ServicesHero;
