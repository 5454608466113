import React from "react";
import styles from "./ClientDisplay.module.css";
import rejuve from "../images/clients/clients-rejuve.jpg";
import romelia from "../images/clients/clients-romelia.jpg";
import alaila from "../images/clients/clients-alaila.jpg";
import infinite from "../images/clients/clients-infinite.jpg";
import boncherie from "../images/clients/clients-boncherie.jpg";
import inmoced from "../images/clients/clients-inmoced.jpg";
import { FaLongArrowAltRight } from "react-icons/fa";

const Clients = () => {
  return (
    <div className={styles.image_wrapper}>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>
            Web Development, Graphic Design
          </p>
          <a
            href="https://www.rejuveestetica.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.content_link}
          >
            Visit <FaLongArrowAltRight style={{ verticalAlign: "top" }} />
          </a>
        </div>
        <img
          src={rejuve}
          alt="rejuve"
          loading="lazy"
          className={styles.image}
        />
      </div>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>
            Web Development, Graphic Design
          </p>
          <a
            href="https://www.romeliaspa.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.content_link}
          >
            Visit <FaLongArrowAltRight style={{ verticalAlign: "top" }} />
          </a>
        </div>
        <img
          src={romelia}
          alt="romelia"
          loading="lazy"
          className={styles.image}
        />
      </div>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>
            Web Development, Graphic Design
          </p>
          <a
            href="https://www.boncheriecenter.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.content_link}
          >
            Visit <FaLongArrowAltRight style={{ verticalAlign: "top" }} />
          </a>
        </div>
        <img
          src={boncherie}
          alt="boncherie"
          loading="lazy"
          className={styles.image}
        />
      </div>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>Web Development, Marketing</p>
          <a
            href="https://www.alailaorganic.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.content_link}
          >
            Visit <FaLongArrowAltRight style={{ verticalAlign: "top" }} />
          </a>
        </div>
        <img
          src={alaila}
          alt="alaila"
          loading="lazy"
          className={styles.image}
        />
      </div>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>Graphic Design & Branding</p>
        </div>
        <img
          src={inmoced}
          alt="inmoced"
          loading="lazy"
          className={styles.image}
        />
      </div>
      <div className={styles.image_container}>
        <div className={styles.content}>
          <p className={styles.content_header}>
            Graphic Design, Software Solutions
          </p>
        </div>
        <img
          src={infinite}
          alt="infinite"
          loading="lazy"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default Clients;
