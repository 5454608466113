import React from "react";
import styles from "./Form.module.css";
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";

const Form = () => {
  return (
    <div className={styles.contact_form}>
      <div className={styles.form_container}>
        <h2 className={styles.form_heading}>Get in Touch</h2>
        <form
          name="contact-form"
          method="POST"
          className={styles.form}
          id="contact-form"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            className={styles.form_input}
          />
          <input
            type="text"
            name="company"
            placeholder="Company"
            className={styles.form_input}
          />
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            className={styles.form_input}
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            className={styles.form_input}
          />
          <textarea
            rows="9"
            cols="70"
            name="message"
            form="contact-form"
            placeholder="Message"
            className={styles.textarea}
          ></textarea>
          <input
            type="submit"
            value="Send Message"
            className={styles.form_button}
          />
        </form>
      </div>
      <div className={styles.content_container}>
        <h2 className={styles.content_heading}>Contact Information</h2>
        <div className={styles.content}>
          <div className={styles.content_item}>
            <div className={styles.icon_container}>
              <FaMapMarkerAlt className={styles.social_icon} />
            </div>
            <p className={styles.content_text}>
              Belgrade, Vitezova Karadjordjeve Zvezde
            </p>
          </div>
          <div className={styles.content_item}>
            <div className={styles.icon_container}>
              <FaEnvelope className={styles.social_icon} />
            </div>
            <p className={styles.content_text}>support@fisbyte.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
