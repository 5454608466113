import React from "react";
import Hero from "../components/home/Hero";
import Navbar from "../components/Navbar";
import Services from "../components/home/Services";
import Partners from "../components/home/Partners";
import Hero2 from "../components/Hero2";
import visionImg from "../images/vision.webp";
import appImg from "../images/fisbyte-app.jpg";
import Footer from "../components/Footer";
import Technologies from "../components/home/Technologies";
import Companies from "../components/home/Companies";
import Testimonials from "../components/home/Testimonials";
import Hero3 from "../components/Hero3";
import Numbers from "../components/home/Numbers";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <Partners />
      <Numbers />
      <Hero2
        text="Fisbyte is constantly looking to innovate as well as offer new solutions to our current clients. In the future we look forward to offering many more services. We firmly believe that supporting AI advancement and cybersecurity is the right path towards a brighter future."
        header="Looking forward towards the future"
        btnText="Learn More"
        showButton={true}
        link="/about"
        img={visionImg}
      />
      <Hero2
        text="Our web application platform will be a service that our clients will be able to request separately. It will help you follow our work with your projects. It will be a very unique service that will facilitate your work with our work, as well as help you stay well informed about the current strategies and goals."
        header="Fisbyte's web application platform"
        btnText="Learn More"
        showButton={true}
        link="/about"
        img={appImg}
      />
      <Technologies />
      <Testimonials />
      <Companies />
      <Hero3 text="Are you looking for a consultation?" button="Get a Quote" />
      <Footer />
    </>
  );
};

export default Home;
