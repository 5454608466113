import React from "react";
import styles from "./Button.module.css";
import { Link } from "react-router-dom";

const Button = (props) => {
  return (
    <Link to={`${props.direction}`}>
      <button className={`${styles.main_button} ${props.className}`}>
        {props.children}
      </button>
    </Link>
  );
};

export default Button;
