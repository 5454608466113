import React from "react";
import AppForm from "../components/requests/AppForm";

const AppRequest = () => {
  return (
    <>
      <AppForm />
    </>
  );
};

export default AppRequest;
