import React from "react";
import styles from "./Partners.module.css";
import siLogo from "../../images/si-logo.svg";
import lynobLogo from "../../images/lynob-logo.svg";

const Partners = () => {
  return (
    <div className={styles.partners_wrapper}>
      <h1 className={styles.partners_heading}>Our Partners</h1>
      <div className={styles.partners_container}>
        <img
          src={lynobLogo}
          alt="lynob"
          height="90px"
          className={styles.partner_logo}
        />
        <img
          src={siLogo}
          alt="soluciones-innovadoras"
          height="100px"
          className={styles.partner_logo}
        />
      </div>
    </div>
  );
};

export default Partners;
