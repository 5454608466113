import React from "react";
import styles from "./Services.module.css";
import service1 from "../../images/service-1.svg";
import service2 from "../../images/service-2.svg";
import service3 from "../../images/service-3.svg";
import service4 from "../../images/service-4.svg";
import Button from "../Button";

const Services = () => {
  return (
    <div>
      <h1 className={`main-heading`}>Our Services</h1>
      <p className={`sub-heading`}>
        We offer a wide range of services ranging from graphic design all the
        way to marketing and web development.
      </p>
      <div className={styles.services_container}>
        <div className={styles.service}>
          <img src={service1} alt="web-dev" height="100px" />
          <h1 className={styles.service_header}>Web Development</h1>
          <p className={styles.service_p}>
            Fully featured websites and web applications designed and suited to
            all your needs
          </p>
          <Button
            className={styles.service_button}
            direction="/web-development"
          >
            Read More
          </Button>
        </div>
        <div className={styles.service}>
          <img src={service2} alt="graphic-design" height="100px" />
          <h1 className={styles.service_header}>Graphic Design</h1>
          <p className={styles.service_p}>
            We offer all kinds of design, ranging from web design to complete
            branding packages
          </p>
          <Button className={styles.service_button} direction="/graphic-design">
            Read More
          </Button>
        </div>
        <div className={styles.service}>
          <img src={service3} alt="digital-marketing" height="100px" />
          <h1 className={styles.service_header}>Digital Marketing</h1>
          <p className={styles.service_p}>
            We help you grow your brand or company seeking to achieve your
            marketing objectives
          </p>
          <Button className={styles.service_button} direction="/marketing">
            Read More
          </Button>
        </div>
        <div className={styles.service}>
          <img src={service4} alt="mobile-apps" height="100px" />
          <h1 className={styles.service_header}>App Development</h1>
          <p className={styles.service_p}>
            Modern mobile solutions for your business that can help you achieve
            your goals
          </p>
          <Button className={styles.service_button} direction="app-development">
            Read More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Services;
