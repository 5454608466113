import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import styles from "../components/Banner.module.css";
import Hero3 from "../components/Hero3";
import Footer from "../components/Footer";
import ClientDisplay from "../components/ClientDisplay";

const Clients = () => {
  return (
    <>
      <Navbar />
      <Banner
        heading="Our Clients"
        description="Companies we currently work with"
        style={styles.container2}
      />
      <ClientDisplay />
      <Hero3 text="Are you looking for a consultation?" button="Get a Quote" />
      <Footer />
    </>
  );
};

export default Clients;
