import React from "react";
import Footer from "../components/Footer";
import Hero3 from "../components/Hero3";
import Navbar from "../components/Navbar";
import Advantages from "../components/Services/Advantages";
import ServicesHero from "../components/Services/ServicesHero";
import appImg from "../images/service-4.svg";

const AppDevelopment = () => {
  return (
    <>
      <Navbar />
      <ServicesHero
        img={appImg}
        heading="App Development"
        text="We help companies solve today's problems with modern apps and software solutions. We focus on improving company performance as well as giving new options for customers."
      />
      <Advantages
        mainHeading="Advantages of having an app"
        subHeading="There are many ways a web app can help your business grow and become more profitable, as well as easier to manage."
        heading1="Communication"
        heading2="Data capture"
        heading3="Productivity"
        heading4="Staff interaction"
        text1="It's much easier to interact with your customers and prospects when you have an app solution, especially if it's made for them to interact with your company through it."
        text2="Valuable data for your business can be captured when your clients interact through your app."
        text3="Clients interacting with you through your app will have much higher chances of converting. It will also streamline the job of your employees and staff."
        text4="Modern apps with modern features will facilitate the communication of your staff, making it very easy to reach anyone in the company and keep everyone connected."
      />
      <Hero3 />
      <Footer />
    </>
  );
};

export default AppDevelopment;
