import React from "react";
import WebForm from "../components/requests/WebForm";

const WebRequest = () => {
  return (
    <>
      <WebForm />
    </>
  );
};

export default WebRequest;
