import React from "react";
import Button from "../Button";
import styles from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.hero_content}>
        <h1 className={styles.heading}>
          Powering the future with
          <span className={styles.heading_color}> digital innovation</span>
        </h1>
        <p className={styles.paragraph}>
          Fisbyte is constantly helping companies achieve their marketing,
          design and digital needs. In today's world, it's vital for any company
          to have a strong technological and digital presence.
        </p>
        <Button direction="/contact" className={styles.link}>
          Let's Talk
        </Button>
      </div>
      <div className={styles.hero_form_container}>
        <h3 className={styles.form_heading}>Ask us a quick question</h3>
        <form
          method="POST"
          name="question-form"
          className={styles.hero_form}
          id="question-form"
        >
          <input type="hidden" name="form-name" value="question-form" />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            className={styles.form_input}
          />
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            className={styles.form_input}
          />
          <input
            type="text"
            name="question"
            placeholder="Question"
            className={styles.form_input}
          />
          <input type="submit" value="Submit" className={styles.form_button} />
        </form>
      </div>
    </div>
  );
};

export default Hero;
