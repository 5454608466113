import React from "react";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import styles from "./Testimonials.module.css";
import { FaQuoteLeft } from "react-icons/fa";
import "../swiper.css";

const Testimonials = () => {
  return (
    <>
      <div className={styles.swiper_container}>
        <Swiper
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className={styles.swiper}
        >
          <SwiperSlide className={styles.swiper_slide}>
            <p className={styles.quote}>
              <FaQuoteLeft className={styles.quote_icon} />
              Fisbyte truly offers world class services and their quality is
              beyond amazing.
            </p>
            <p className={styles.author}>Dra. Alba Collado | Boncherie</p>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <p className={styles.quote}>
              <FaQuoteLeft className={styles.quote_icon} />
              It's for sure one of the best software companies we have worked
              with.
            </p>
            <p className={styles.author}>Joel Cedeño | Rejuve Estetica</p>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <p className={styles.quote}>
              <FaQuoteLeft className={styles.quote_icon} />
              Their focus on customer service and satisfaction is unmatched.
            </p>
            <p className={styles.author}>Franklin Hidalgo | Romelia Spa</p>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Testimonials;
