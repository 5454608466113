import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Hero3 from "../components/Hero3";
import styles from "../components/Banner.module.css";

const FAQ = () => {
  return (
    <>
      <Navbar />
      <Banner
        heading="Frequently Asked Questions"
        description="Do you want to know something"
        style={styles.container}
      />
      <Faq />
      <Hero3 />
      <Footer />
    </>
  );
};

export default FAQ;
