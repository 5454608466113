import React from "react";
import Button from "./Button";
import styles from "./Hero3.module.css";

const Hero3 = (props) => {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>
          Are you looking for a web or software solution?
        </h1>
        <Button className={styles.button} direction="/request">
          Send Request
        </Button>
      </div>
    </>
  );
};

export default Hero3;
