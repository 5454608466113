import React from "react";
import Button from "./Button";
import styles from "./CallToAction.module.css";

const CallToAction = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.cta_heading}>{props.heading}</h1>
      <p className={styles.cta_body}>{props.body}</p>
      <Button direction={props.link}>{props.button}</Button>
    </div>
  );
};

export default CallToAction;
