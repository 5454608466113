import React from "react";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import styles from "./Request.module.css";

const Request = () => {
  return (
    <div>
      <Banner />
      <div className={styles.container}>
        <Link to="/web-request" className={styles.link}>
          <div className={styles.card}>
            <h2 className={styles.heading}>Web Solution</h2>
          </div>
        </Link>
        <Link to="/app-request" className={styles.link}>
          <div className={styles.card}>
            <h2 className={styles.heading}>App Solution</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Request;
