import React from "react";
import Footer from "../components/Footer";
import Hero3 from "../components/Hero3";
import Navbar from "../components/Navbar";
import Advantages from "../components/Services/Advantages";
import ServicesHero from "../components/Services/ServicesHero";
import marketingImg from "../images/service-3.svg";

const Marketing = () => {
  return (
    <>
      <Navbar />
      <ServicesHero
        img={marketingImg}
        heading="Marketing"
        text="Do you need SEO, digital marketing or social media management? Don't worry we have you covered!"
      />
      <Advantages
        mainHeading="Kinds of digital marketing"
        subHeading="4 forms of digital marketing that all modern companies should employ."
        heading1="SEO"
        heading2="Social media"
        heading3="Content marketing"
        heading4="Email marketing"
        text1="The goal of SEO is to get a business to rank higher in Google search results, ultimately increasing search engine traffic to the business’s website."
        text2="This includes everything a business does via social media channels. Just about everyone is familiar with social media, but marketers must approach social society with an integrated and strategic approach."
        text3="Content marketing uses storytelling and information sharing to increase brand awareness. Ultimately, the goal is to have the reader take action towards becoming a customer."
        text4="Even with the emergence of social media, mobile applications and other channels, email is still one of the most effective marketing techniques. It can be part of a content marketing strategy, providing value to consumers."
      />
      <Hero3 />
      <Footer />
    </>
  );
};

export default Marketing;
