import React from "react";
import styles from "./Faq.module.css";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqItem = (props) => {
  return (
    <AccordionItem className={styles.accordion_item}>
      <AccordionItemHeading>
        <AccordionItemButton className={styles.accordion_button}>
          {props.question}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={styles.accordion_panel}>
        <p>{props.answer}</p>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default FaqItem;
