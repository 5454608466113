import React from "react";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import styles from "../components/Banner.module.css";
import Hero2 from "../components/Hero2";
import Hero3 from "../components/Hero3";
import aboutImg from "../images/about/about-img.jpg";
import whatWeDoImg from "../images/about/whatwedo-img.jpg";
import HowWeWork from "../components/about/HowWeWork";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import Values from "../components/about/Values";

const About = () => {
  return (
    <>
      <Navbar />
      <Banner
        style={styles.container}
        heading="About Us"
        description="Learn more about the things we do"
      />
      <Hero2
        header="Who we are"
        text="We are a software and digital company that looks to help new businesses as well as already well established ones have a strong brand presence as well as great digital and software solutions that can facilitate and improve their work. We initially started as a small mobile game development company but we often had requests for different services, which later on made us change our direction and focus more on innovative digital and branded solutions."
        img={aboutImg}
      />
      <Hero2
        header="What we do"
        img={whatWeDoImg}
        flexDirection="row-reverse"
        text="We develop future proven high quality applications. We believe that guiding companies in today's world where it's impossible to function as a company without modern digital solutions is essential. This makes us one of the few companies that will really try to understand your needs as our customer and what would be the best solution for your new idea or problem, as well as how to bring it to a digital market where there are no boundaries nor limits."
      />
      <HowWeWork />
      <CallToAction
        heading="We believe in simplicity"
        body="On modern websites we believe that people don't have a lot of time to spend all day reading web pages. That's why we focus on creating quick, straight-to-point solutions. So that clients don't lose interest do to countless pages that don't show them what they really want to know."
        button="Learn More"
        link="/graphic-design"
      />
      <Values />
      <Hero3 />
      <Footer />
    </>
  );
};

export default About;
